var __assign = this && this.__assign || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = this && this.__spreadArrays || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
    return r;
};
import { Composer, TDLibError } from './components';
export function pick(obj, paths) {
    return __assign({}, paths.reduce(function (mem, key) {
        var _a;
        return __assign(__assign({}, mem), (_a = {}, _a[key] = obj[key], _a));
    }, {}));
}
export function createDeferred() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var deferred = {};
    deferred.promise = new Promise(function (resolve, reject) {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}
export function isError(obj) {
    return obj && '_' in obj && obj._ === 'error';
}
export function toObject(_a) {
    var response = _a.response;
    if (isError(response)) {
        throw new TDLibError(response.code, response.message);
    }
    return response;
}
export var on = function (predicateTypes) {
    var fns = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fns[_i - 1] = arguments[_i];
    }
    return Composer.mount.apply(Composer, __spreadArrays([predicateTypes], fns));
};