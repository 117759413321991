export var SUPERGROUP_MEMBERS_FILTER;
(function (SUPERGROUP_MEMBERS_FILTER) {
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterRecent"] = "supergroupMembersFilterRecent";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterContacts"] = "supergroupMembersFilterContacts";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterAdministrators"] = "supergroupMembersFilterAdministrators";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterSearch"] = "supergroupMembersFilterSearch";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterRestricted"] = "supergroupMembersFilterRestricted";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterBanned"] = "supergroupMembersFilterBanned";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterMention"] = "supergroupMembersFilterMention";
    SUPERGROUP_MEMBERS_FILTER["supergroupMembersFilterBots"] = "supergroupMembersFilterBots";
})(SUPERGROUP_MEMBERS_FILTER || (SUPERGROUP_MEMBERS_FILTER = {}));