export var USER_PRIVACY_SETTING;
(function (USER_PRIVACY_SETTING) {
    USER_PRIVACY_SETTING["userPrivacySettingShowStatus"] = "userPrivacySettingShowStatus";
    USER_PRIVACY_SETTING["userPrivacySettingShowProfilePhoto"] = "userPrivacySettingShowProfilePhoto";
    USER_PRIVACY_SETTING["userPrivacySettingShowLinkInForwardedMessages"] = "userPrivacySettingShowLinkInForwardedMessages";
    USER_PRIVACY_SETTING["userPrivacySettingShowPhoneNumber"] = "userPrivacySettingShowPhoneNumber";
    USER_PRIVACY_SETTING["userPrivacySettingAllowChatInvites"] = "userPrivacySettingAllowChatInvites";
    USER_PRIVACY_SETTING["userPrivacySettingAllowCalls"] = "userPrivacySettingAllowCalls";
    USER_PRIVACY_SETTING["userPrivacySettingAllowPeerToPeerCalls"] = "userPrivacySettingAllowPeerToPeerCalls";
    USER_PRIVACY_SETTING["userPrivacySettingAllowFindingByPhoneNumber"] = "userPrivacySettingAllowFindingByPhoneNumber";
})(USER_PRIVACY_SETTING || (USER_PRIVACY_SETTING = {}));