var __extends = this && this.__extends || function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function (d, b) {
            d.__proto__ = b;
        } || function (d, b) {
            for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() {
            this.constructor = d;
        }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
}();
var __rest = this && this.__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
};
import { AirgramCore } from '@airgram/core';
import { Provider } from './Provider';
var Airgram = /** @class */function (_super) {
    __extends(Airgram, _super);
    function Airgram(config) {
        var _this = this;
        var instanceName = config.instanceName,
            isBackground = config.isBackground,
            jsLogVerbosityLevel = config.jsLogVerbosityLevel,
            logVerbosityLevel = config.logVerbosityLevel,
            mode = config.mode,
            readOnly = config.readOnly,
            useDatabase = config.useDatabase,
            baseConfig = __rest(config, ["instanceName", "isBackground", "jsLogVerbosityLevel", "logVerbosityLevel", "mode", "readOnly", "useDatabase"]);
        var providerFactory = function (handleUpdate) {
            var provider = new Provider({
                instanceName: instanceName,
                isBackground: isBackground,
                jsLogVerbosityLevel: jsLogVerbosityLevel,
                logVerbosityLevel: logVerbosityLevel,
                mode: mode,
                readOnly: readOnly,
                useDatabase: useDatabase
            });
            provider.initialize(handleUpdate);
            return provider;
        };
        _this = _super.call(this, providerFactory, baseConfig) || this;
        return _this;
    }
    return Airgram;
}(AirgramCore);
export { Airgram };