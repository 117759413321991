export var PAGE_BLOCK;
(function (PAGE_BLOCK) {
    PAGE_BLOCK["pageBlockTitle"] = "pageBlockTitle";
    PAGE_BLOCK["pageBlockSubtitle"] = "pageBlockSubtitle";
    PAGE_BLOCK["pageBlockAuthorDate"] = "pageBlockAuthorDate";
    PAGE_BLOCK["pageBlockHeader"] = "pageBlockHeader";
    PAGE_BLOCK["pageBlockSubheader"] = "pageBlockSubheader";
    PAGE_BLOCK["pageBlockKicker"] = "pageBlockKicker";
    PAGE_BLOCK["pageBlockParagraph"] = "pageBlockParagraph";
    PAGE_BLOCK["pageBlockPreformatted"] = "pageBlockPreformatted";
    PAGE_BLOCK["pageBlockFooter"] = "pageBlockFooter";
    PAGE_BLOCK["pageBlockDivider"] = "pageBlockDivider";
    PAGE_BLOCK["pageBlockAnchor"] = "pageBlockAnchor";
    PAGE_BLOCK["pageBlockList"] = "pageBlockList";
    PAGE_BLOCK["pageBlockBlockQuote"] = "pageBlockBlockQuote";
    PAGE_BLOCK["pageBlockPullQuote"] = "pageBlockPullQuote";
    PAGE_BLOCK["pageBlockAnimation"] = "pageBlockAnimation";
    PAGE_BLOCK["pageBlockAudio"] = "pageBlockAudio";
    PAGE_BLOCK["pageBlockPhoto"] = "pageBlockPhoto";
    PAGE_BLOCK["pageBlockVideo"] = "pageBlockVideo";
    PAGE_BLOCK["pageBlockVoiceNote"] = "pageBlockVoiceNote";
    PAGE_BLOCK["pageBlockCover"] = "pageBlockCover";
    PAGE_BLOCK["pageBlockEmbedded"] = "pageBlockEmbedded";
    PAGE_BLOCK["pageBlockEmbeddedPost"] = "pageBlockEmbeddedPost";
    PAGE_BLOCK["pageBlockCollage"] = "pageBlockCollage";
    PAGE_BLOCK["pageBlockSlideshow"] = "pageBlockSlideshow";
    PAGE_BLOCK["pageBlockChatLink"] = "pageBlockChatLink";
    PAGE_BLOCK["pageBlockTable"] = "pageBlockTable";
    PAGE_BLOCK["pageBlockDetails"] = "pageBlockDetails";
    PAGE_BLOCK["pageBlockRelatedArticles"] = "pageBlockRelatedArticles";
    PAGE_BLOCK["pageBlockMap"] = "pageBlockMap";
})(PAGE_BLOCK || (PAGE_BLOCK = {}));