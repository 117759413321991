export var MESSAGE_CONTENT;
(function (MESSAGE_CONTENT) {
    MESSAGE_CONTENT["messageText"] = "messageText";
    MESSAGE_CONTENT["messageAnimation"] = "messageAnimation";
    MESSAGE_CONTENT["messageAudio"] = "messageAudio";
    MESSAGE_CONTENT["messageDocument"] = "messageDocument";
    MESSAGE_CONTENT["messagePhoto"] = "messagePhoto";
    MESSAGE_CONTENT["messageExpiredPhoto"] = "messageExpiredPhoto";
    MESSAGE_CONTENT["messageSticker"] = "messageSticker";
    MESSAGE_CONTENT["messageVideo"] = "messageVideo";
    MESSAGE_CONTENT["messageExpiredVideo"] = "messageExpiredVideo";
    MESSAGE_CONTENT["messageVideoNote"] = "messageVideoNote";
    MESSAGE_CONTENT["messageVoiceNote"] = "messageVoiceNote";
    MESSAGE_CONTENT["messageLocation"] = "messageLocation";
    MESSAGE_CONTENT["messageVenue"] = "messageVenue";
    MESSAGE_CONTENT["messageContact"] = "messageContact";
    MESSAGE_CONTENT["messageAnimatedEmoji"] = "messageAnimatedEmoji";
    MESSAGE_CONTENT["messageDice"] = "messageDice";
    MESSAGE_CONTENT["messageGame"] = "messageGame";
    MESSAGE_CONTENT["messagePoll"] = "messagePoll";
    MESSAGE_CONTENT["messageInvoice"] = "messageInvoice";
    MESSAGE_CONTENT["messageCall"] = "messageCall";
    MESSAGE_CONTENT["messageVideoChatScheduled"] = "messageVideoChatScheduled";
    MESSAGE_CONTENT["messageVideoChatStarted"] = "messageVideoChatStarted";
    MESSAGE_CONTENT["messageVideoChatEnded"] = "messageVideoChatEnded";
    MESSAGE_CONTENT["messageInviteVideoChatParticipants"] = "messageInviteVideoChatParticipants";
    MESSAGE_CONTENT["messageBasicGroupChatCreate"] = "messageBasicGroupChatCreate";
    MESSAGE_CONTENT["messageSupergroupChatCreate"] = "messageSupergroupChatCreate";
    MESSAGE_CONTENT["messageChatChangeTitle"] = "messageChatChangeTitle";
    MESSAGE_CONTENT["messageChatChangePhoto"] = "messageChatChangePhoto";
    MESSAGE_CONTENT["messageChatDeletePhoto"] = "messageChatDeletePhoto";
    MESSAGE_CONTENT["messageChatAddMembers"] = "messageChatAddMembers";
    MESSAGE_CONTENT["messageChatJoinByLink"] = "messageChatJoinByLink";
    MESSAGE_CONTENT["messageChatJoinByRequest"] = "messageChatJoinByRequest";
    MESSAGE_CONTENT["messageChatDeleteMember"] = "messageChatDeleteMember";
    MESSAGE_CONTENT["messageChatUpgradeTo"] = "messageChatUpgradeTo";
    MESSAGE_CONTENT["messageChatUpgradeFrom"] = "messageChatUpgradeFrom";
    MESSAGE_CONTENT["messagePinMessage"] = "messagePinMessage";
    MESSAGE_CONTENT["messageScreenshotTaken"] = "messageScreenshotTaken";
    MESSAGE_CONTENT["messageChatSetTheme"] = "messageChatSetTheme";
    MESSAGE_CONTENT["messageChatSetTtl"] = "messageChatSetTtl";
    MESSAGE_CONTENT["messageCustomServiceAction"] = "messageCustomServiceAction";
    MESSAGE_CONTENT["messageGameScore"] = "messageGameScore";
    MESSAGE_CONTENT["messagePaymentSuccessful"] = "messagePaymentSuccessful";
    MESSAGE_CONTENT["messagePaymentSuccessfulBot"] = "messagePaymentSuccessfulBot";
    MESSAGE_CONTENT["messageContactRegistered"] = "messageContactRegistered";
    MESSAGE_CONTENT["messageWebsiteConnected"] = "messageWebsiteConnected";
    MESSAGE_CONTENT["messagePassportDataSent"] = "messagePassportDataSent";
    MESSAGE_CONTENT["messagePassportDataReceived"] = "messagePassportDataReceived";
    MESSAGE_CONTENT["messageProximityAlertTriggered"] = "messageProximityAlertTriggered";
    MESSAGE_CONTENT["messageUnsupported"] = "messageUnsupported";
})(MESSAGE_CONTENT || (MESSAGE_CONTENT = {}));