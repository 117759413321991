var __extends = this && this.__extends || function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function (d, b) {
            d.__proto__ = b;
        } || function (d, b) {
            for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() {
            this.constructor = d;
        }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
}();
var __assign = this && this.__assign || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
        return value instanceof P ? value : new P(function (resolve) {
            resolve(value);
        });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        }
        function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        }
        function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = this && this.__generator || function (thisArg, body) {
    var _ = { label: 0, sent: function () {
            if (t[0] & 1) throw t[1];return t[1];
        }, trys: [], ops: [] },
        f,
        y,
        t,
        g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
        return this;
    }), g;
    function verb(n) {
        return function (v) {
            return step([n, v]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0:case 1:
                    t = op;break;
                case 4:
                    _.label++;return { value: op[1], done: false };
                case 5:
                    _.label++;y = op[1];op = [0];continue;
                case 7:
                    op = _.ops.pop();_.trys.pop();continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];t = op;break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];_.ops.push(op);break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [6, e];y = 0;
        } finally {
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Provider as BaseTdProvider } from '@airgram/core';
import TdClient from 'tdweb';
import { deserializerFactory, serializerFactory } from './utils';
var Provider = /** @class */function (_super) {
    __extends(Provider, _super);
    function Provider(config) {
        if (config === void 0) {
            config = {};
        }
        var _this = _super.call(this) || this;
        _this.client = null;
        _this.config = {};
        _this.config = config;
        _this.serialize = serializerFactory();
        _this.deserialize = deserializerFactory();
        return _this;
    }
    Provider.prototype.destroy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('[Airgram] for web does not support `destroy()` method.');
            });
        });
    };
    Provider.prototype.execute = function () {
        throw new Error('[Airgram] tdweb does not support synchronous operations.');
    };
    Provider.prototype.initialize = function (handleUpdate) {
        var _this = this;
        this.client = new TdClient(__assign(__assign({}, this.config), { onUpdate: function (update) {
                return handleUpdate(_this.deserialize(update));
            } }));
    };
    Provider.prototype.send = function (_a) {
        var _this = this;
        var method = _a.method,
            params = _a.params;
        if (!this.client) {
            throw new Error('[Airgram] tdweb instance has not initialized yet.');
        }
        return this.client.send(this.serialize(__assign({ _: method }, params))).then(function (data) {
            return _this.deserialize(data);
        }).catch(function (error) {
            if ('@type' in error && error['@type']) {
                var data = _this.deserialize(error);
                // Ensures that the message property is a string
                // See: https://github.com/tdlib/td/blob/master/example/web/tdweb/src/index.js#L648
                data.message = String(data.message);
                return data;
            } else {
                throw error;
            }
        });
    };
    return Provider;
}(BaseTdProvider);
export { Provider };