export var USER_PRIVACY_SETTING_RULE;
(function (USER_PRIVACY_SETTING_RULE) {
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleAllowAll"] = "userPrivacySettingRuleAllowAll";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleAllowContacts"] = "userPrivacySettingRuleAllowContacts";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleAllowUsers"] = "userPrivacySettingRuleAllowUsers";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleAllowChatMembers"] = "userPrivacySettingRuleAllowChatMembers";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleRestrictAll"] = "userPrivacySettingRuleRestrictAll";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleRestrictContacts"] = "userPrivacySettingRuleRestrictContacts";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleRestrictUsers"] = "userPrivacySettingRuleRestrictUsers";
    USER_PRIVACY_SETTING_RULE["userPrivacySettingRuleRestrictChatMembers"] = "userPrivacySettingRuleRestrictChatMembers";
})(USER_PRIVACY_SETTING_RULE || (USER_PRIVACY_SETTING_RULE = {}));