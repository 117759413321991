export var PUSH_MESSAGE_CONTENT;
(function (PUSH_MESSAGE_CONTENT) {
    PUSH_MESSAGE_CONTENT["pushMessageContentHidden"] = "pushMessageContentHidden";
    PUSH_MESSAGE_CONTENT["pushMessageContentAnimation"] = "pushMessageContentAnimation";
    PUSH_MESSAGE_CONTENT["pushMessageContentAudio"] = "pushMessageContentAudio";
    PUSH_MESSAGE_CONTENT["pushMessageContentContact"] = "pushMessageContentContact";
    PUSH_MESSAGE_CONTENT["pushMessageContentContactRegistered"] = "pushMessageContentContactRegistered";
    PUSH_MESSAGE_CONTENT["pushMessageContentDocument"] = "pushMessageContentDocument";
    PUSH_MESSAGE_CONTENT["pushMessageContentGame"] = "pushMessageContentGame";
    PUSH_MESSAGE_CONTENT["pushMessageContentGameScore"] = "pushMessageContentGameScore";
    PUSH_MESSAGE_CONTENT["pushMessageContentInvoice"] = "pushMessageContentInvoice";
    PUSH_MESSAGE_CONTENT["pushMessageContentLocation"] = "pushMessageContentLocation";
    PUSH_MESSAGE_CONTENT["pushMessageContentPhoto"] = "pushMessageContentPhoto";
    PUSH_MESSAGE_CONTENT["pushMessageContentPoll"] = "pushMessageContentPoll";
    PUSH_MESSAGE_CONTENT["pushMessageContentScreenshotTaken"] = "pushMessageContentScreenshotTaken";
    PUSH_MESSAGE_CONTENT["pushMessageContentSticker"] = "pushMessageContentSticker";
    PUSH_MESSAGE_CONTENT["pushMessageContentText"] = "pushMessageContentText";
    PUSH_MESSAGE_CONTENT["pushMessageContentVideo"] = "pushMessageContentVideo";
    PUSH_MESSAGE_CONTENT["pushMessageContentVideoNote"] = "pushMessageContentVideoNote";
    PUSH_MESSAGE_CONTENT["pushMessageContentVoiceNote"] = "pushMessageContentVoiceNote";
    PUSH_MESSAGE_CONTENT["pushMessageContentBasicGroupChatCreate"] = "pushMessageContentBasicGroupChatCreate";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatAddMembers"] = "pushMessageContentChatAddMembers";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatChangePhoto"] = "pushMessageContentChatChangePhoto";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatChangeTitle"] = "pushMessageContentChatChangeTitle";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatSetTheme"] = "pushMessageContentChatSetTheme";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatDeleteMember"] = "pushMessageContentChatDeleteMember";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatJoinByLink"] = "pushMessageContentChatJoinByLink";
    PUSH_MESSAGE_CONTENT["pushMessageContentChatJoinByRequest"] = "pushMessageContentChatJoinByRequest";
    PUSH_MESSAGE_CONTENT["pushMessageContentMessageForwards"] = "pushMessageContentMessageForwards";
    PUSH_MESSAGE_CONTENT["pushMessageContentMediaAlbum"] = "pushMessageContentMediaAlbum";
})(PUSH_MESSAGE_CONTENT || (PUSH_MESSAGE_CONTENT = {}));