export var INPUT_PASSPORT_ELEMENT;
(function (INPUT_PASSPORT_ELEMENT) {
    INPUT_PASSPORT_ELEMENT["inputPassportElementPersonalDetails"] = "inputPassportElementPersonalDetails";
    INPUT_PASSPORT_ELEMENT["inputPassportElementPassport"] = "inputPassportElementPassport";
    INPUT_PASSPORT_ELEMENT["inputPassportElementDriverLicense"] = "inputPassportElementDriverLicense";
    INPUT_PASSPORT_ELEMENT["inputPassportElementIdentityCard"] = "inputPassportElementIdentityCard";
    INPUT_PASSPORT_ELEMENT["inputPassportElementInternalPassport"] = "inputPassportElementInternalPassport";
    INPUT_PASSPORT_ELEMENT["inputPassportElementAddress"] = "inputPassportElementAddress";
    INPUT_PASSPORT_ELEMENT["inputPassportElementUtilityBill"] = "inputPassportElementUtilityBill";
    INPUT_PASSPORT_ELEMENT["inputPassportElementBankStatement"] = "inputPassportElementBankStatement";
    INPUT_PASSPORT_ELEMENT["inputPassportElementRentalAgreement"] = "inputPassportElementRentalAgreement";
    INPUT_PASSPORT_ELEMENT["inputPassportElementPassportRegistration"] = "inputPassportElementPassportRegistration";
    INPUT_PASSPORT_ELEMENT["inputPassportElementTemporaryRegistration"] = "inputPassportElementTemporaryRegistration";
    INPUT_PASSPORT_ELEMENT["inputPassportElementPhoneNumber"] = "inputPassportElementPhoneNumber";
    INPUT_PASSPORT_ELEMENT["inputPassportElementEmailAddress"] = "inputPassportElementEmailAddress";
})(INPUT_PASSPORT_ELEMENT || (INPUT_PASSPORT_ELEMENT = {}));