export var CHAT_EVENT_ACTION;
(function (CHAT_EVENT_ACTION) {
    CHAT_EVENT_ACTION["chatEventMessageEdited"] = "chatEventMessageEdited";
    CHAT_EVENT_ACTION["chatEventMessageDeleted"] = "chatEventMessageDeleted";
    CHAT_EVENT_ACTION["chatEventMessagePinned"] = "chatEventMessagePinned";
    CHAT_EVENT_ACTION["chatEventMessageUnpinned"] = "chatEventMessageUnpinned";
    CHAT_EVENT_ACTION["chatEventPollStopped"] = "chatEventPollStopped";
    CHAT_EVENT_ACTION["chatEventMemberJoined"] = "chatEventMemberJoined";
    CHAT_EVENT_ACTION["chatEventMemberJoinedByInviteLink"] = "chatEventMemberJoinedByInviteLink";
    CHAT_EVENT_ACTION["chatEventMemberJoinedByRequest"] = "chatEventMemberJoinedByRequest";
    CHAT_EVENT_ACTION["chatEventMemberInvited"] = "chatEventMemberInvited";
    CHAT_EVENT_ACTION["chatEventMemberLeft"] = "chatEventMemberLeft";
    CHAT_EVENT_ACTION["chatEventMemberPromoted"] = "chatEventMemberPromoted";
    CHAT_EVENT_ACTION["chatEventMemberRestricted"] = "chatEventMemberRestricted";
    CHAT_EVENT_ACTION["chatEventAvailableReactionsChanged"] = "chatEventAvailableReactionsChanged";
    CHAT_EVENT_ACTION["chatEventDescriptionChanged"] = "chatEventDescriptionChanged";
    CHAT_EVENT_ACTION["chatEventLinkedChatChanged"] = "chatEventLinkedChatChanged";
    CHAT_EVENT_ACTION["chatEventLocationChanged"] = "chatEventLocationChanged";
    CHAT_EVENT_ACTION["chatEventMessageTtlChanged"] = "chatEventMessageTtlChanged";
    CHAT_EVENT_ACTION["chatEventPermissionsChanged"] = "chatEventPermissionsChanged";
    CHAT_EVENT_ACTION["chatEventPhotoChanged"] = "chatEventPhotoChanged";
    CHAT_EVENT_ACTION["chatEventSlowModeDelayChanged"] = "chatEventSlowModeDelayChanged";
    CHAT_EVENT_ACTION["chatEventStickerSetChanged"] = "chatEventStickerSetChanged";
    CHAT_EVENT_ACTION["chatEventTitleChanged"] = "chatEventTitleChanged";
    CHAT_EVENT_ACTION["chatEventUsernameChanged"] = "chatEventUsernameChanged";
    CHAT_EVENT_ACTION["chatEventHasProtectedContentToggled"] = "chatEventHasProtectedContentToggled";
    CHAT_EVENT_ACTION["chatEventInvitesToggled"] = "chatEventInvitesToggled";
    CHAT_EVENT_ACTION["chatEventIsAllHistoryAvailableToggled"] = "chatEventIsAllHistoryAvailableToggled";
    CHAT_EVENT_ACTION["chatEventSignMessagesToggled"] = "chatEventSignMessagesToggled";
    CHAT_EVENT_ACTION["chatEventInviteLinkEdited"] = "chatEventInviteLinkEdited";
    CHAT_EVENT_ACTION["chatEventInviteLinkRevoked"] = "chatEventInviteLinkRevoked";
    CHAT_EVENT_ACTION["chatEventInviteLinkDeleted"] = "chatEventInviteLinkDeleted";
    CHAT_EVENT_ACTION["chatEventVideoChatCreated"] = "chatEventVideoChatCreated";
    CHAT_EVENT_ACTION["chatEventVideoChatEnded"] = "chatEventVideoChatEnded";
    CHAT_EVENT_ACTION["chatEventVideoChatMuteNewParticipantsToggled"] = "chatEventVideoChatMuteNewParticipantsToggled";
    CHAT_EVENT_ACTION["chatEventVideoChatParticipantIsMutedToggled"] = "chatEventVideoChatParticipantIsMutedToggled";
    CHAT_EVENT_ACTION["chatEventVideoChatParticipantVolumeLevelChanged"] = "chatEventVideoChatParticipantVolumeLevelChanged";
})(CHAT_EVENT_ACTION || (CHAT_EVENT_ACTION = {}));