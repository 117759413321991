export var INPUT_INLINE_QUERY_RESULT;
(function (INPUT_INLINE_QUERY_RESULT) {
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultAnimation"] = "inputInlineQueryResultAnimation";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultArticle"] = "inputInlineQueryResultArticle";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultAudio"] = "inputInlineQueryResultAudio";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultContact"] = "inputInlineQueryResultContact";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultDocument"] = "inputInlineQueryResultDocument";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultGame"] = "inputInlineQueryResultGame";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultLocation"] = "inputInlineQueryResultLocation";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultPhoto"] = "inputInlineQueryResultPhoto";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultSticker"] = "inputInlineQueryResultSticker";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultVenue"] = "inputInlineQueryResultVenue";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultVideo"] = "inputInlineQueryResultVideo";
    INPUT_INLINE_QUERY_RESULT["inputInlineQueryResultVoiceNote"] = "inputInlineQueryResultVoiceNote";
})(INPUT_INLINE_QUERY_RESULT || (INPUT_INLINE_QUERY_RESULT = {}));