/* tslint:disable:no-empty */
var __spreadArrays = this && this.__spreadArrays || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
    return r;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var noop = function () {};
function normalizePredicateArguments(argument, prefix) {
    var args = Array.isArray(argument) ? argument : [argument];
    return args.filter(function (item) {
        return item;
    }).map(function (arg) {
        return arg && prefix && !arg.startsWith(prefix) ? "" + prefix + arg : arg;
    });
}
function unwrap(handler) {
    if (handler && 'middleware' in handler) {
        return handler.middleware();
    }
    return handler;
}
function safePassThru() {
    return function (_ctx, next) {
        return typeof next === 'function' ? next() : Promise.resolve();
    };
}
function lazy(factoryFn) {
    return function (ctx, next) {
        return Promise.resolve(factoryFn(ctx)).then(function (middleware) {
            return unwrap(middleware)(ctx, next || noop);
        });
    };
}
function compose(middlewares) {
    if (!Array.isArray(middlewares)) {
        throw new Error('Middleware list must be an array');
    }
    if (middlewares.length === 0) {
        return safePassThru();
    }
    if (middlewares.length === 1) {
        return unwrap(middlewares[0]);
    }
    return function (ctx, next) {
        var index = -1;
        return function execute(i) {
            if (i <= index) {
                return Promise.reject(new Error('next() called multiple times'));
            }
            index = i;
            var handler = unwrap(middlewares[i]) || next;
            if (!handler) {
                return Promise.resolve();
            }
            try {
                return Promise.resolve(handler(ctx, function () {
                    return execute(i + 1);
                }));
            } catch (error) {
                return Promise.reject(error);
            }
        }(0);
    };
}
function branch(predicate, trueMiddleware, falseMiddleware) {
    if (typeof predicate !== 'function') {
        return unwrap(predicate ? trueMiddleware : falseMiddleware);
    }
    return lazy(function (ctx) {
        return Promise.resolve(predicate(ctx)).then(function (value) {
            return value ? trueMiddleware : falseMiddleware;
        });
    });
}
function optional(predicate) {
    var fns = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fns[_i - 1] = arguments[_i];
    }
    return branch(predicate, compose(fns), safePassThru());
}
function mount(predicateType) {
    var fns = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fns[_i - 1] = arguments[_i];
    }
    var predicateTypes = normalizePredicateArguments(predicateType);
    var predicate = function (ctx) {
        return '_' in ctx && predicateTypes.includes(ctx._);
    };
    return optional.apply(void 0, __spreadArrays([predicate], fns));
}
function fork(middleware) {
    return function (_ctx, next) {
        setTimeout(unwrap(middleware), 0);
        return next();
    };
}
// function tap (handler) {
//   return (ctx, next) => Promise.resolve(handler(ctx))
//     .then(() => next())
//     .catch(() => next())
// }
//
// export function drop<ContextT> (predicate): MiddlewarePromise<ContextT> {
//   return branch(predicate, noop, safePassThru())
// }
function filter(predicate) {
    return branch(predicate, safePassThru(), noop);
}
var Composer = /** @class */function () {
    function Composer() {
        var fns = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            fns[_i] = arguments[_i];
        }
        this.handler = compose(fns);
    }
    Composer.prototype.middleware = function () {
        return this.handler;
    };
    Composer.prototype.on = function (predicateTypes) {
        var fns = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            fns[_i - 1] = arguments[_i];
        }
        this.use(mount.apply(void 0, __spreadArrays([predicateTypes], fns)));
    };
    Composer.prototype.use = function () {
        var fns = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            fns[_i] = arguments[_i];
        }
        this.handler = compose(__spreadArrays([this.handler], fns));
    };
    Composer.compose = compose;
    Composer.fork = fork;
    Composer.filter = filter;
    Composer.branch = branch;
    Composer.mount = mount;
    Composer.optional = optional;
    Composer.noop = noop;
    return Composer;
}();
export { Composer };