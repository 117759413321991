export var FILE_TYPE;
(function (FILE_TYPE) {
    FILE_TYPE["fileTypeNone"] = "fileTypeNone";
    FILE_TYPE["fileTypeAnimation"] = "fileTypeAnimation";
    FILE_TYPE["fileTypeAudio"] = "fileTypeAudio";
    FILE_TYPE["fileTypeDocument"] = "fileTypeDocument";
    FILE_TYPE["fileTypePhoto"] = "fileTypePhoto";
    FILE_TYPE["fileTypeProfilePhoto"] = "fileTypeProfilePhoto";
    FILE_TYPE["fileTypeSecret"] = "fileTypeSecret";
    FILE_TYPE["fileTypeSecretThumbnail"] = "fileTypeSecretThumbnail";
    FILE_TYPE["fileTypeSecure"] = "fileTypeSecure";
    FILE_TYPE["fileTypeSticker"] = "fileTypeSticker";
    FILE_TYPE["fileTypeThumbnail"] = "fileTypeThumbnail";
    FILE_TYPE["fileTypeUnknown"] = "fileTypeUnknown";
    FILE_TYPE["fileTypeVideo"] = "fileTypeVideo";
    FILE_TYPE["fileTypeVideoNote"] = "fileTypeVideoNote";
    FILE_TYPE["fileTypeVoiceNote"] = "fileTypeVoiceNote";
    FILE_TYPE["fileTypeWallpaper"] = "fileTypeWallpaper";
})(FILE_TYPE || (FILE_TYPE = {}));