export var CHAT_REPORT_REASON;
(function (CHAT_REPORT_REASON) {
    CHAT_REPORT_REASON["chatReportReasonSpam"] = "chatReportReasonSpam";
    CHAT_REPORT_REASON["chatReportReasonViolence"] = "chatReportReasonViolence";
    CHAT_REPORT_REASON["chatReportReasonPornography"] = "chatReportReasonPornography";
    CHAT_REPORT_REASON["chatReportReasonChildAbuse"] = "chatReportReasonChildAbuse";
    CHAT_REPORT_REASON["chatReportReasonCopyright"] = "chatReportReasonCopyright";
    CHAT_REPORT_REASON["chatReportReasonUnrelatedLocation"] = "chatReportReasonUnrelatedLocation";
    CHAT_REPORT_REASON["chatReportReasonFake"] = "chatReportReasonFake";
    CHAT_REPORT_REASON["chatReportReasonIllegalDrugs"] = "chatReportReasonIllegalDrugs";
    CHAT_REPORT_REASON["chatReportReasonPersonalDetails"] = "chatReportReasonPersonalDetails";
    CHAT_REPORT_REASON["chatReportReasonCustom"] = "chatReportReasonCustom";
})(CHAT_REPORT_REASON || (CHAT_REPORT_REASON = {}));