export var UPDATE;
(function (UPDATE) {
    UPDATE["updateAuthorizationState"] = "updateAuthorizationState";
    UPDATE["updateNewMessage"] = "updateNewMessage";
    UPDATE["updateMessageSendAcknowledged"] = "updateMessageSendAcknowledged";
    UPDATE["updateMessageSendSucceeded"] = "updateMessageSendSucceeded";
    UPDATE["updateMessageSendFailed"] = "updateMessageSendFailed";
    UPDATE["updateMessageContent"] = "updateMessageContent";
    UPDATE["updateMessageEdited"] = "updateMessageEdited";
    UPDATE["updateMessageIsPinned"] = "updateMessageIsPinned";
    UPDATE["updateMessageInteractionInfo"] = "updateMessageInteractionInfo";
    UPDATE["updateMessageContentOpened"] = "updateMessageContentOpened";
    UPDATE["updateMessageMentionRead"] = "updateMessageMentionRead";
    UPDATE["updateMessageUnreadReactions"] = "updateMessageUnreadReactions";
    UPDATE["updateMessageLiveLocationViewed"] = "updateMessageLiveLocationViewed";
    UPDATE["updateNewChat"] = "updateNewChat";
    UPDATE["updateChatTitle"] = "updateChatTitle";
    UPDATE["updateChatPhoto"] = "updateChatPhoto";
    UPDATE["updateChatPermissions"] = "updateChatPermissions";
    UPDATE["updateChatLastMessage"] = "updateChatLastMessage";
    UPDATE["updateChatPosition"] = "updateChatPosition";
    UPDATE["updateChatReadInbox"] = "updateChatReadInbox";
    UPDATE["updateChatReadOutbox"] = "updateChatReadOutbox";
    UPDATE["updateChatActionBar"] = "updateChatActionBar";
    UPDATE["updateChatAvailableReactions"] = "updateChatAvailableReactions";
    UPDATE["updateChatDraftMessage"] = "updateChatDraftMessage";
    UPDATE["updateChatMessageSender"] = "updateChatMessageSender";
    UPDATE["updateChatMessageTtl"] = "updateChatMessageTtl";
    UPDATE["updateChatNotificationSettings"] = "updateChatNotificationSettings";
    UPDATE["updateChatPendingJoinRequests"] = "updateChatPendingJoinRequests";
    UPDATE["updateChatReplyMarkup"] = "updateChatReplyMarkup";
    UPDATE["updateChatTheme"] = "updateChatTheme";
    UPDATE["updateChatUnreadMentionCount"] = "updateChatUnreadMentionCount";
    UPDATE["updateChatUnreadReactionCount"] = "updateChatUnreadReactionCount";
    UPDATE["updateChatVideoChat"] = "updateChatVideoChat";
    UPDATE["updateChatDefaultDisableNotification"] = "updateChatDefaultDisableNotification";
    UPDATE["updateChatHasProtectedContent"] = "updateChatHasProtectedContent";
    UPDATE["updateChatHasScheduledMessages"] = "updateChatHasScheduledMessages";
    UPDATE["updateChatIsBlocked"] = "updateChatIsBlocked";
    UPDATE["updateChatIsMarkedAsUnread"] = "updateChatIsMarkedAsUnread";
    UPDATE["updateChatFilters"] = "updateChatFilters";
    UPDATE["updateChatOnlineMemberCount"] = "updateChatOnlineMemberCount";
    UPDATE["updateScopeNotificationSettings"] = "updateScopeNotificationSettings";
    UPDATE["updateNotification"] = "updateNotification";
    UPDATE["updateNotificationGroup"] = "updateNotificationGroup";
    UPDATE["updateActiveNotifications"] = "updateActiveNotifications";
    UPDATE["updateHavePendingNotifications"] = "updateHavePendingNotifications";
    UPDATE["updateDeleteMessages"] = "updateDeleteMessages";
    UPDATE["updateChatAction"] = "updateChatAction";
    UPDATE["updateUserStatus"] = "updateUserStatus";
    UPDATE["updateUser"] = "updateUser";
    UPDATE["updateBasicGroup"] = "updateBasicGroup";
    UPDATE["updateSupergroup"] = "updateSupergroup";
    UPDATE["updateSecretChat"] = "updateSecretChat";
    UPDATE["updateUserFullInfo"] = "updateUserFullInfo";
    UPDATE["updateBasicGroupFullInfo"] = "updateBasicGroupFullInfo";
    UPDATE["updateSupergroupFullInfo"] = "updateSupergroupFullInfo";
    UPDATE["updateServiceNotification"] = "updateServiceNotification";
    UPDATE["updateFile"] = "updateFile";
    UPDATE["updateFileGenerationStart"] = "updateFileGenerationStart";
    UPDATE["updateFileGenerationStop"] = "updateFileGenerationStop";
    UPDATE["updateFileDownloads"] = "updateFileDownloads";
    UPDATE["updateFileAddedToDownloads"] = "updateFileAddedToDownloads";
    UPDATE["updateFileDownload"] = "updateFileDownload";
    UPDATE["updateFileRemovedFromDownloads"] = "updateFileRemovedFromDownloads";
    UPDATE["updateCall"] = "updateCall";
    UPDATE["updateGroupCall"] = "updateGroupCall";
    UPDATE["updateGroupCallParticipant"] = "updateGroupCallParticipant";
    UPDATE["updateNewCallSignalingData"] = "updateNewCallSignalingData";
    UPDATE["updateUserPrivacySettingRules"] = "updateUserPrivacySettingRules";
    UPDATE["updateUnreadMessageCount"] = "updateUnreadMessageCount";
    UPDATE["updateUnreadChatCount"] = "updateUnreadChatCount";
    UPDATE["updateOption"] = "updateOption";
    UPDATE["updateStickerSet"] = "updateStickerSet";
    UPDATE["updateInstalledStickerSets"] = "updateInstalledStickerSets";
    UPDATE["updateTrendingStickerSets"] = "updateTrendingStickerSets";
    UPDATE["updateRecentStickers"] = "updateRecentStickers";
    UPDATE["updateFavoriteStickers"] = "updateFavoriteStickers";
    UPDATE["updateSavedAnimations"] = "updateSavedAnimations";
    UPDATE["updateSelectedBackground"] = "updateSelectedBackground";
    UPDATE["updateChatThemes"] = "updateChatThemes";
    UPDATE["updateLanguagePackStrings"] = "updateLanguagePackStrings";
    UPDATE["updateConnectionState"] = "updateConnectionState";
    UPDATE["updateTermsOfService"] = "updateTermsOfService";
    UPDATE["updateUsersNearby"] = "updateUsersNearby";
    UPDATE["updateReactions"] = "updateReactions";
    UPDATE["updateDiceEmojis"] = "updateDiceEmojis";
    UPDATE["updateAnimatedEmojiMessageClicked"] = "updateAnimatedEmojiMessageClicked";
    UPDATE["updateAnimationSearchParameters"] = "updateAnimationSearchParameters";
    UPDATE["updateSuggestedActions"] = "updateSuggestedActions";
    UPDATE["updateNewInlineQuery"] = "updateNewInlineQuery";
    UPDATE["updateNewChosenInlineResult"] = "updateNewChosenInlineResult";
    UPDATE["updateNewCallbackQuery"] = "updateNewCallbackQuery";
    UPDATE["updateNewInlineCallbackQuery"] = "updateNewInlineCallbackQuery";
    UPDATE["updateNewShippingQuery"] = "updateNewShippingQuery";
    UPDATE["updateNewPreCheckoutQuery"] = "updateNewPreCheckoutQuery";
    UPDATE["updateNewCustomEvent"] = "updateNewCustomEvent";
    UPDATE["updateNewCustomQuery"] = "updateNewCustomQuery";
    UPDATE["updatePoll"] = "updatePoll";
    UPDATE["updatePollAnswer"] = "updatePollAnswer";
    UPDATE["updateChatMember"] = "updateChatMember";
    UPDATE["updateNewChatJoinRequest"] = "updateNewChatJoinRequest";
})(UPDATE || (UPDATE = {}));