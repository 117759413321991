export var INPUT_PASSPORT_ELEMENT_ERROR_SOURCE;
(function (INPUT_PASSPORT_ELEMENT_ERROR_SOURCE) {
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceUnspecified"] = "inputPassportElementErrorSourceUnspecified";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceDataField"] = "inputPassportElementErrorSourceDataField";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceFrontSide"] = "inputPassportElementErrorSourceFrontSide";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceReverseSide"] = "inputPassportElementErrorSourceReverseSide";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceSelfie"] = "inputPassportElementErrorSourceSelfie";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceTranslationFile"] = "inputPassportElementErrorSourceTranslationFile";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceTranslationFiles"] = "inputPassportElementErrorSourceTranslationFiles";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceFile"] = "inputPassportElementErrorSourceFile";
    INPUT_PASSPORT_ELEMENT_ERROR_SOURCE["inputPassportElementErrorSourceFiles"] = "inputPassportElementErrorSourceFiles";
})(INPUT_PASSPORT_ELEMENT_ERROR_SOURCE || (INPUT_PASSPORT_ELEMENT_ERROR_SOURCE = {}));