export var AUTHORIZATION_STATE;
(function (AUTHORIZATION_STATE) {
    AUTHORIZATION_STATE["authorizationStateWaitTdlibParameters"] = "authorizationStateWaitTdlibParameters";
    AUTHORIZATION_STATE["authorizationStateWaitEncryptionKey"] = "authorizationStateWaitEncryptionKey";
    AUTHORIZATION_STATE["authorizationStateWaitPhoneNumber"] = "authorizationStateWaitPhoneNumber";
    AUTHORIZATION_STATE["authorizationStateWaitCode"] = "authorizationStateWaitCode";
    AUTHORIZATION_STATE["authorizationStateWaitOtherDeviceConfirmation"] = "authorizationStateWaitOtherDeviceConfirmation";
    AUTHORIZATION_STATE["authorizationStateWaitRegistration"] = "authorizationStateWaitRegistration";
    AUTHORIZATION_STATE["authorizationStateWaitPassword"] = "authorizationStateWaitPassword";
    AUTHORIZATION_STATE["authorizationStateReady"] = "authorizationStateReady";
    AUTHORIZATION_STATE["authorizationStateLoggingOut"] = "authorizationStateLoggingOut";
    AUTHORIZATION_STATE["authorizationStateClosing"] = "authorizationStateClosing";
    AUTHORIZATION_STATE["authorizationStateClosed"] = "authorizationStateClosed";
})(AUTHORIZATION_STATE || (AUTHORIZATION_STATE = {}));