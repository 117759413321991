export var SEARCH_MESSAGES_FILTER;
(function (SEARCH_MESSAGES_FILTER) {
    SEARCH_MESSAGES_FILTER["searchMessagesFilterEmpty"] = "searchMessagesFilterEmpty";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterAnimation"] = "searchMessagesFilterAnimation";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterAudio"] = "searchMessagesFilterAudio";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterDocument"] = "searchMessagesFilterDocument";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterPhoto"] = "searchMessagesFilterPhoto";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterVideo"] = "searchMessagesFilterVideo";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterVoiceNote"] = "searchMessagesFilterVoiceNote";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterPhotoAndVideo"] = "searchMessagesFilterPhotoAndVideo";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterUrl"] = "searchMessagesFilterUrl";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterChatPhoto"] = "searchMessagesFilterChatPhoto";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterVideoNote"] = "searchMessagesFilterVideoNote";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterVoiceAndVideoNote"] = "searchMessagesFilterVoiceAndVideoNote";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterMention"] = "searchMessagesFilterMention";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterUnreadMention"] = "searchMessagesFilterUnreadMention";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterUnreadReaction"] = "searchMessagesFilterUnreadReaction";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterFailedToSend"] = "searchMessagesFilterFailedToSend";
    SEARCH_MESSAGES_FILTER["searchMessagesFilterPinned"] = "searchMessagesFilterPinned";
})(SEARCH_MESSAGES_FILTER || (SEARCH_MESSAGES_FILTER = {}));