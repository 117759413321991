var __extends = this && this.__extends || function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function (d, b) {
            d.__proto__ = b;
        } || function (d, b) {
            for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() {
            this.constructor = d;
        }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
}();
var TDLibError = /** @class */function (_super) {
    __extends(TDLibError, _super);
    function TDLibError(code, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'TDLibError';
        _this.code = code;
        _this.stack = new Error().stack;
        Error.call(_this, _this.message);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(_this, TDLibError);
        } else {
            _this.stack = new Error().stack;
        }
        return _this;
    }
    TDLibError.prototype.toString = function () {
        return this.message;
    };
    return TDLibError;
}(Error);
export { TDLibError };