export var CALL_PROBLEM;
(function (CALL_PROBLEM) {
    CALL_PROBLEM["callProblemEcho"] = "callProblemEcho";
    CALL_PROBLEM["callProblemNoise"] = "callProblemNoise";
    CALL_PROBLEM["callProblemInterruptions"] = "callProblemInterruptions";
    CALL_PROBLEM["callProblemDistortedSpeech"] = "callProblemDistortedSpeech";
    CALL_PROBLEM["callProblemSilentLocal"] = "callProblemSilentLocal";
    CALL_PROBLEM["callProblemSilentRemote"] = "callProblemSilentRemote";
    CALL_PROBLEM["callProblemDropped"] = "callProblemDropped";
    CALL_PROBLEM["callProblemDistortedVideo"] = "callProblemDistortedVideo";
    CALL_PROBLEM["callProblemPixelatedVideo"] = "callProblemPixelatedVideo";
})(CALL_PROBLEM || (CALL_PROBLEM = {}));