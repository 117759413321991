export var INTERNAL_LINK_TYPE;
(function (INTERNAL_LINK_TYPE) {
    INTERNAL_LINK_TYPE["internalLinkTypeActiveSessions"] = "internalLinkTypeActiveSessions";
    INTERNAL_LINK_TYPE["internalLinkTypeAuthenticationCode"] = "internalLinkTypeAuthenticationCode";
    INTERNAL_LINK_TYPE["internalLinkTypeBackground"] = "internalLinkTypeBackground";
    INTERNAL_LINK_TYPE["internalLinkTypeBotStart"] = "internalLinkTypeBotStart";
    INTERNAL_LINK_TYPE["internalLinkTypeBotStartInGroup"] = "internalLinkTypeBotStartInGroup";
    INTERNAL_LINK_TYPE["internalLinkTypeChangePhoneNumber"] = "internalLinkTypeChangePhoneNumber";
    INTERNAL_LINK_TYPE["internalLinkTypeChatInvite"] = "internalLinkTypeChatInvite";
    INTERNAL_LINK_TYPE["internalLinkTypeFilterSettings"] = "internalLinkTypeFilterSettings";
    INTERNAL_LINK_TYPE["internalLinkTypeGame"] = "internalLinkTypeGame";
    INTERNAL_LINK_TYPE["internalLinkTypeLanguagePack"] = "internalLinkTypeLanguagePack";
    INTERNAL_LINK_TYPE["internalLinkTypeLanguageSettings"] = "internalLinkTypeLanguageSettings";
    INTERNAL_LINK_TYPE["internalLinkTypeMessage"] = "internalLinkTypeMessage";
    INTERNAL_LINK_TYPE["internalLinkTypeMessageDraft"] = "internalLinkTypeMessageDraft";
    INTERNAL_LINK_TYPE["internalLinkTypePassportDataRequest"] = "internalLinkTypePassportDataRequest";
    INTERNAL_LINK_TYPE["internalLinkTypePhoneNumberConfirmation"] = "internalLinkTypePhoneNumberConfirmation";
    INTERNAL_LINK_TYPE["internalLinkTypePrivacyAndSecuritySettings"] = "internalLinkTypePrivacyAndSecuritySettings";
    INTERNAL_LINK_TYPE["internalLinkTypeProxy"] = "internalLinkTypeProxy";
    INTERNAL_LINK_TYPE["internalLinkTypePublicChat"] = "internalLinkTypePublicChat";
    INTERNAL_LINK_TYPE["internalLinkTypeQrCodeAuthentication"] = "internalLinkTypeQrCodeAuthentication";
    INTERNAL_LINK_TYPE["internalLinkTypeSettings"] = "internalLinkTypeSettings";
    INTERNAL_LINK_TYPE["internalLinkTypeStickerSet"] = "internalLinkTypeStickerSet";
    INTERNAL_LINK_TYPE["internalLinkTypeTheme"] = "internalLinkTypeTheme";
    INTERNAL_LINK_TYPE["internalLinkTypeThemeSettings"] = "internalLinkTypeThemeSettings";
    INTERNAL_LINK_TYPE["internalLinkTypeUnknownDeepLink"] = "internalLinkTypeUnknownDeepLink";
    INTERNAL_LINK_TYPE["internalLinkTypeUnsupportedProxy"] = "internalLinkTypeUnsupportedProxy";
    INTERNAL_LINK_TYPE["internalLinkTypeUserPhoneNumber"] = "internalLinkTypeUserPhoneNumber";
    INTERNAL_LINK_TYPE["internalLinkTypeVideoChat"] = "internalLinkTypeVideoChat";
})(INTERNAL_LINK_TYPE || (INTERNAL_LINK_TYPE = {}));