export var PASSPORT_ELEMENT_TYPE;
(function (PASSPORT_ELEMENT_TYPE) {
    PASSPORT_ELEMENT_TYPE["passportElementTypePersonalDetails"] = "passportElementTypePersonalDetails";
    PASSPORT_ELEMENT_TYPE["passportElementTypePassport"] = "passportElementTypePassport";
    PASSPORT_ELEMENT_TYPE["passportElementTypeDriverLicense"] = "passportElementTypeDriverLicense";
    PASSPORT_ELEMENT_TYPE["passportElementTypeIdentityCard"] = "passportElementTypeIdentityCard";
    PASSPORT_ELEMENT_TYPE["passportElementTypeInternalPassport"] = "passportElementTypeInternalPassport";
    PASSPORT_ELEMENT_TYPE["passportElementTypeAddress"] = "passportElementTypeAddress";
    PASSPORT_ELEMENT_TYPE["passportElementTypeUtilityBill"] = "passportElementTypeUtilityBill";
    PASSPORT_ELEMENT_TYPE["passportElementTypeBankStatement"] = "passportElementTypeBankStatement";
    PASSPORT_ELEMENT_TYPE["passportElementTypeRentalAgreement"] = "passportElementTypeRentalAgreement";
    PASSPORT_ELEMENT_TYPE["passportElementTypePassportRegistration"] = "passportElementTypePassportRegistration";
    PASSPORT_ELEMENT_TYPE["passportElementTypeTemporaryRegistration"] = "passportElementTypeTemporaryRegistration";
    PASSPORT_ELEMENT_TYPE["passportElementTypePhoneNumber"] = "passportElementTypePhoneNumber";
    PASSPORT_ELEMENT_TYPE["passportElementTypeEmailAddress"] = "passportElementTypeEmailAddress";
})(PASSPORT_ELEMENT_TYPE || (PASSPORT_ELEMENT_TYPE = {}));