export var INLINE_KEYBOARD_BUTTON_TYPE;
(function (INLINE_KEYBOARD_BUTTON_TYPE) {
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeUrl"] = "inlineKeyboardButtonTypeUrl";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeLoginUrl"] = "inlineKeyboardButtonTypeLoginUrl";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeCallback"] = "inlineKeyboardButtonTypeCallback";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeCallbackWithPassword"] = "inlineKeyboardButtonTypeCallbackWithPassword";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeCallbackGame"] = "inlineKeyboardButtonTypeCallbackGame";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeSwitchInline"] = "inlineKeyboardButtonTypeSwitchInline";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeBuy"] = "inlineKeyboardButtonTypeBuy";
    INLINE_KEYBOARD_BUTTON_TYPE["inlineKeyboardButtonTypeUser"] = "inlineKeyboardButtonTypeUser";
})(INLINE_KEYBOARD_BUTTON_TYPE || (INLINE_KEYBOARD_BUTTON_TYPE = {}));