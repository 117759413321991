export var PASSPORT_ELEMENT;
(function (PASSPORT_ELEMENT) {
    PASSPORT_ELEMENT["passportElementPersonalDetails"] = "passportElementPersonalDetails";
    PASSPORT_ELEMENT["passportElementPassport"] = "passportElementPassport";
    PASSPORT_ELEMENT["passportElementDriverLicense"] = "passportElementDriverLicense";
    PASSPORT_ELEMENT["passportElementIdentityCard"] = "passportElementIdentityCard";
    PASSPORT_ELEMENT["passportElementInternalPassport"] = "passportElementInternalPassport";
    PASSPORT_ELEMENT["passportElementAddress"] = "passportElementAddress";
    PASSPORT_ELEMENT["passportElementUtilityBill"] = "passportElementUtilityBill";
    PASSPORT_ELEMENT["passportElementBankStatement"] = "passportElementBankStatement";
    PASSPORT_ELEMENT["passportElementRentalAgreement"] = "passportElementRentalAgreement";
    PASSPORT_ELEMENT["passportElementPassportRegistration"] = "passportElementPassportRegistration";
    PASSPORT_ELEMENT["passportElementTemporaryRegistration"] = "passportElementTemporaryRegistration";
    PASSPORT_ELEMENT["passportElementPhoneNumber"] = "passportElementPhoneNumber";
    PASSPORT_ELEMENT["passportElementEmailAddress"] = "passportElementEmailAddress";
})(PASSPORT_ELEMENT || (PASSPORT_ELEMENT = {}));