export var INLINE_QUERY_RESULT;
(function (INLINE_QUERY_RESULT) {
    INLINE_QUERY_RESULT["inlineQueryResultArticle"] = "inlineQueryResultArticle";
    INLINE_QUERY_RESULT["inlineQueryResultContact"] = "inlineQueryResultContact";
    INLINE_QUERY_RESULT["inlineQueryResultLocation"] = "inlineQueryResultLocation";
    INLINE_QUERY_RESULT["inlineQueryResultVenue"] = "inlineQueryResultVenue";
    INLINE_QUERY_RESULT["inlineQueryResultGame"] = "inlineQueryResultGame";
    INLINE_QUERY_RESULT["inlineQueryResultAnimation"] = "inlineQueryResultAnimation";
    INLINE_QUERY_RESULT["inlineQueryResultAudio"] = "inlineQueryResultAudio";
    INLINE_QUERY_RESULT["inlineQueryResultDocument"] = "inlineQueryResultDocument";
    INLINE_QUERY_RESULT["inlineQueryResultPhoto"] = "inlineQueryResultPhoto";
    INLINE_QUERY_RESULT["inlineQueryResultSticker"] = "inlineQueryResultSticker";
    INLINE_QUERY_RESULT["inlineQueryResultVideo"] = "inlineQueryResultVideo";
    INLINE_QUERY_RESULT["inlineQueryResultVoiceNote"] = "inlineQueryResultVoiceNote";
})(INLINE_QUERY_RESULT || (INLINE_QUERY_RESULT = {}));