function camelCase(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (_m, chr) {
        return chr.toUpperCase();
    });
}
function snakeCase(str) {
    return str.replace(/^\w/, function (chr) {
        return chr.toLowerCase();
    }).replace(/[A-Z]/g, function (chr) {
        return "_" + chr.toLowerCase();
    });
}
function isSerializable(value) {
    return typeof value === 'object' && !!value && ('_' in value || '@type' in value);
}
function isSerializableArray(value) {
    return Array.isArray(value) && !!value.length && isSerializable(value[0]);
}
export function deserializerFactory() {
    var keyMap = new Map();
    function deserialize(value) {
        if (isSerializable(value)) {
            var replacement_1 = { _: 'UNKNOWN' };
            Object.keys(value).forEach(function (k) {
                if (k === '@type' && value['@type']) {
                    replacement_1._ = value['@type'];
                    return;
                }
                if (!k) {
                    return;
                }
                if (k.charAt(0) === '@') {
                    replacement_1[k] = value[k];
                    return;
                }
                if (!keyMap.has(k)) {
                    keyMap.set(k, camelCase(k));
                }
                var v = value[k];
                var key = keyMap.get(k);
                if (isSerializableArray(v)) {
                    replacement_1[key] = v.map(function (x) {
                        return deserialize(x);
                    });
                } else if (isSerializable(v)) {
                    replacement_1[key] = deserialize(v);
                } else {
                    replacement_1[key] = v;
                }
            });
            return replacement_1;
        }
        if (!value) {
            return value;
        }
        throw new Error("[Airgram][deserialize] invalid value: " + value);
    }
    return deserialize;
}
export function serializerFactory() {
    var keyMap = new Map();
    function serialize(value) {
        if (isSerializable(value)) {
            var replacement_2 = { '@type': 'UNKNOWN' };
            Object.keys(value).forEach(function (k) {
                if (k === '_' && value._) {
                    replacement_2['@type'] = value._;
                    return;
                }
                if (!k) {
                    return;
                }
                if (k.charAt(0) === '@') {
                    replacement_2[k] = value[k];
                    return;
                }
                if (!keyMap.has(k)) {
                    keyMap.set(k, snakeCase(k));
                }
                var v = value[k];
                var key = keyMap.get(k);
                if (isSerializableArray(v)) {
                    replacement_2[key] = v.map(serialize);
                } else if (isSerializable(v)) {
                    replacement_2[key] = serialize(v);
                } else {
                    replacement_2[key] = v;
                }
            });
            return replacement_2;
        }
        throw new Error("[Airgram][serialize] invalid value: " + value);
    }
    return serialize;
}