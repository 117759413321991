export var CHAT_ACTION;
(function (CHAT_ACTION) {
    CHAT_ACTION["chatActionTyping"] = "chatActionTyping";
    CHAT_ACTION["chatActionRecordingVideo"] = "chatActionRecordingVideo";
    CHAT_ACTION["chatActionUploadingVideo"] = "chatActionUploadingVideo";
    CHAT_ACTION["chatActionRecordingVoiceNote"] = "chatActionRecordingVoiceNote";
    CHAT_ACTION["chatActionUploadingVoiceNote"] = "chatActionUploadingVoiceNote";
    CHAT_ACTION["chatActionUploadingPhoto"] = "chatActionUploadingPhoto";
    CHAT_ACTION["chatActionUploadingDocument"] = "chatActionUploadingDocument";
    CHAT_ACTION["chatActionChoosingSticker"] = "chatActionChoosingSticker";
    CHAT_ACTION["chatActionChoosingLocation"] = "chatActionChoosingLocation";
    CHAT_ACTION["chatActionChoosingContact"] = "chatActionChoosingContact";
    CHAT_ACTION["chatActionStartPlayingGame"] = "chatActionStartPlayingGame";
    CHAT_ACTION["chatActionRecordingVideoNote"] = "chatActionRecordingVideoNote";
    CHAT_ACTION["chatActionUploadingVideoNote"] = "chatActionUploadingVideoNote";
    CHAT_ACTION["chatActionWatchingAnimations"] = "chatActionWatchingAnimations";
    CHAT_ACTION["chatActionCancel"] = "chatActionCancel";
})(CHAT_ACTION || (CHAT_ACTION = {}));