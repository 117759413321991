export var INPUT_MESSAGE_CONTENT;
(function (INPUT_MESSAGE_CONTENT) {
    INPUT_MESSAGE_CONTENT["inputMessageText"] = "inputMessageText";
    INPUT_MESSAGE_CONTENT["inputMessageAnimation"] = "inputMessageAnimation";
    INPUT_MESSAGE_CONTENT["inputMessageAudio"] = "inputMessageAudio";
    INPUT_MESSAGE_CONTENT["inputMessageDocument"] = "inputMessageDocument";
    INPUT_MESSAGE_CONTENT["inputMessagePhoto"] = "inputMessagePhoto";
    INPUT_MESSAGE_CONTENT["inputMessageSticker"] = "inputMessageSticker";
    INPUT_MESSAGE_CONTENT["inputMessageVideo"] = "inputMessageVideo";
    INPUT_MESSAGE_CONTENT["inputMessageVideoNote"] = "inputMessageVideoNote";
    INPUT_MESSAGE_CONTENT["inputMessageVoiceNote"] = "inputMessageVoiceNote";
    INPUT_MESSAGE_CONTENT["inputMessageLocation"] = "inputMessageLocation";
    INPUT_MESSAGE_CONTENT["inputMessageVenue"] = "inputMessageVenue";
    INPUT_MESSAGE_CONTENT["inputMessageContact"] = "inputMessageContact";
    INPUT_MESSAGE_CONTENT["inputMessageDice"] = "inputMessageDice";
    INPUT_MESSAGE_CONTENT["inputMessageGame"] = "inputMessageGame";
    INPUT_MESSAGE_CONTENT["inputMessageInvoice"] = "inputMessageInvoice";
    INPUT_MESSAGE_CONTENT["inputMessagePoll"] = "inputMessagePoll";
    INPUT_MESSAGE_CONTENT["inputMessageForwarded"] = "inputMessageForwarded";
})(INPUT_MESSAGE_CONTENT || (INPUT_MESSAGE_CONTENT = {}));