export var DEVICE_TOKEN;
(function (DEVICE_TOKEN) {
    DEVICE_TOKEN["deviceTokenFirebaseCloudMessaging"] = "deviceTokenFirebaseCloudMessaging";
    DEVICE_TOKEN["deviceTokenApplePush"] = "deviceTokenApplePush";
    DEVICE_TOKEN["deviceTokenApplePushVoIP"] = "deviceTokenApplePushVoIP";
    DEVICE_TOKEN["deviceTokenWindowsPush"] = "deviceTokenWindowsPush";
    DEVICE_TOKEN["deviceTokenMicrosoftPush"] = "deviceTokenMicrosoftPush";
    DEVICE_TOKEN["deviceTokenMicrosoftPushVoIP"] = "deviceTokenMicrosoftPushVoIP";
    DEVICE_TOKEN["deviceTokenWebPush"] = "deviceTokenWebPush";
    DEVICE_TOKEN["deviceTokenSimplePush"] = "deviceTokenSimplePush";
    DEVICE_TOKEN["deviceTokenUbuntuPush"] = "deviceTokenUbuntuPush";
    DEVICE_TOKEN["deviceTokenBlackBerryPush"] = "deviceTokenBlackBerryPush";
    DEVICE_TOKEN["deviceTokenTizenPush"] = "deviceTokenTizenPush";
})(DEVICE_TOKEN || (DEVICE_TOKEN = {}));