export var PASSPORT_ELEMENT_ERROR_SOURCE;
(function (PASSPORT_ELEMENT_ERROR_SOURCE) {
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceUnspecified"] = "passportElementErrorSourceUnspecified";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceDataField"] = "passportElementErrorSourceDataField";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceFrontSide"] = "passportElementErrorSourceFrontSide";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceReverseSide"] = "passportElementErrorSourceReverseSide";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceSelfie"] = "passportElementErrorSourceSelfie";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceTranslationFile"] = "passportElementErrorSourceTranslationFile";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceTranslationFiles"] = "passportElementErrorSourceTranslationFiles";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceFile"] = "passportElementErrorSourceFile";
    PASSPORT_ELEMENT_ERROR_SOURCE["passportElementErrorSourceFiles"] = "passportElementErrorSourceFiles";
})(PASSPORT_ELEMENT_ERROR_SOURCE || (PASSPORT_ELEMENT_ERROR_SOURCE = {}));