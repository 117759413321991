export var RICH_TEXT;
(function (RICH_TEXT) {
    RICH_TEXT["richTextPlain"] = "richTextPlain";
    RICH_TEXT["richTextBold"] = "richTextBold";
    RICH_TEXT["richTextItalic"] = "richTextItalic";
    RICH_TEXT["richTextUnderline"] = "richTextUnderline";
    RICH_TEXT["richTextStrikethrough"] = "richTextStrikethrough";
    RICH_TEXT["richTextFixed"] = "richTextFixed";
    RICH_TEXT["richTextUrl"] = "richTextUrl";
    RICH_TEXT["richTextEmailAddress"] = "richTextEmailAddress";
    RICH_TEXT["richTextSubscript"] = "richTextSubscript";
    RICH_TEXT["richTextSuperscript"] = "richTextSuperscript";
    RICH_TEXT["richTextMarked"] = "richTextMarked";
    RICH_TEXT["richTextPhoneNumber"] = "richTextPhoneNumber";
    RICH_TEXT["richTextIcon"] = "richTextIcon";
    RICH_TEXT["richTextReference"] = "richTextReference";
    RICH_TEXT["richTextAnchor"] = "richTextAnchor";
    RICH_TEXT["richTextAnchorLink"] = "richTextAnchorLink";
    RICH_TEXT["richTexts"] = "richTexts";
})(RICH_TEXT || (RICH_TEXT = {}));