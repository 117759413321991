/* eslint-disable no-dupe-class-members */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
        return value instanceof P ? value : new P(function (resolve) {
            resolve(value);
        });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        }
        function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        }
        function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = this && this.__generator || function (thisArg, body) {
    var _ = { label: 0, sent: function () {
            if (t[0] & 1) throw t[1];return t[1];
        }, trys: [], ops: [] },
        f,
        y,
        t,
        g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
        return this;
    }), g;
    function verb(n) {
        return function (v) {
            return step([n, v]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0:case 1:
                    t = op;break;
                case 4:
                    _.label++;return { value: op[1], done: false };
                case 5:
                    _.label++;y = op[1];op = [0];continue;
                case 7:
                    op = _.ops.pop();_.trys.pop();continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];t = op;break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];_.ops.push(op);break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [6, e];y = 0;
        } finally {
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { authIndependentMethods, AUTHORIZATION_STATE, UPDATE } from '@airgram/constants';
import { Composer, isError, Provider } from '@airgram/core';
var authMethods = ['addProxy', 'disableProxy', 'editProxy', 'enableProxy', 'getProxyLink', 'pingProxy', 'removeProxy', 'checkAuthenticationBotToken', 'checkAuthenticationCode', 'checkAuthenticationPassword', 'checkDatabaseEncryptionKey', 'getAuthorizationState', 'getLogStream', 'getLogTagVerbosityLevel', 'getLogTags', 'getLogVerbosityLevel', 'registerUser', 'requestAuthenticationPasswordRecovery', 'resendAuthenticationCode', 'setAuthenticationPhoneNumber', 'setOption', 'setTdlibParameters', 'setLogStream', 'setLogTagVerbosityLevel', 'setLogVerbosityLevel'];
var Auth = /** @class */function () {
    function Auth(config) {
        this.maxAttempts = 3;
        this.answers = {};
        this.attempt = 0;
        this.authState = null;
        this.config = {};
        this.deferred = null;
        this.invalidPhoneNumbers = new Set();
        this.config = config;
    }
    Object.defineProperty(Auth.prototype, "isAuthorized", {
        get: function () {
            return !!(this.authState && this.authState._ === AUTHORIZATION_STATE.authorizationStateReady);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Auth.prototype, "isBot", {
        get: function () {
            return 'token' in this.config;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Auth.prototype, "airgram", {
        get: function () {
            if (!this._airgram) {
                throw new Error('Airgram has not been initialized yet.');
            }
            return this._airgram;
        },
        enumerable: false,
        configurable: true
    });
    Auth.prototype.middleware = function () {
        var _this = this;
        return Composer.compose([function (ctx, next) {
            if (!_this._airgram) {
                _this._airgram = ctx.airgram;
            }
            return next();
        }, Composer.filter(function (ctx) {
            return !('update' in ctx) || !ctx.update || ctx.update._ !== UPDATE.updateAuthorizationState || !_this.handleUpdateState(ctx.update);
        }), Composer.optional(function (ctx) {
            return !_this.isAuthorized && !authMethods.includes(ctx._) && !authIndependentMethods.includes(ctx._);
        }, function (_ctx, next) {
            return _this.login().then(next);
        })]);
    };
    Auth.prototype.ask = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!(type in this.config) || !this.config[type]) {
                    throw new Error("The \"" + type + "\" option does not specified.");
                }
                if (typeof this.config[type] === 'function') {
                    return [2 /*return*/, this.config[type]()];
                }
                return [2 /*return*/, this.config[type]];
            });
        });
    };
    Auth.prototype.askPhoneNumber = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.ask('phoneNumber').then(function (phoneNumber) {
                    return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (!phoneNumber) {
                                throw new Error('Phone number is not defined');
                            }
                            if (this.invalidPhoneNumbers.has(phoneNumber) || !/^\+?\d{10,}$/.test(phoneNumber.trim())) {
                                this.invalidPhoneNumbers.add(phoneNumber);
                                delete this.answers.phoneNumber;
                                throw new Error('Invalid phone number');
                            }
                            return [2 /*return*/, phoneNumber];
                        });
                    });
                })];
            });
        });
    };
    Auth.prototype.checkAuthenticationPassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = (_a = this.airgram.api).checkAuthenticationPassword;
                        _c = {};
                        return [4 /*yield*/, this.ask('password')];
                    case 1:
                        return [2 /*return*/, _b.apply(_a, [(_c.password = _d.sent(), _c)])];
                }
            });
        });
    };
    Auth.prototype.fatalError = function (error) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.error("[Airgram Auth] quit due an error: \"" + error.message + "\"");
                        if (!(this.airgram.provider instanceof Provider)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.airgram.provider.destroy()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        return [2 /*return*/, false];
                }
            });
        });
    };
    Auth.prototype.handleError = function (error) {
        return __awaiter(this, void 0, void 0, function () {
            var promise;
            var _this = this;
            return __generator(this, function (_a) {
                promise = null;
                if (error.code === 429) {
                    return [2 /*return*/, this.fatalError(new Error(error.message))];
                }
                switch (error.message) {
                    case 'PHONE_NUMBER_INVALID':
                        {
                            if (this.answers.phoneNumber) {
                                this.invalidPhoneNumbers.add(this.answers.phoneNumber);
                                delete this.answers.phoneNumber;
                            }
                            promise = this.askPhoneNumber().then(function (phoneNumber) {
                                return _this.airgram.api.setAuthenticationPhoneNumber({ phoneNumber: phoneNumber });
                            });
                            break;
                        }
                    case 'PHONE_CODE_EMPTY':
                    case 'PHONE_CODE_EXPIRED':
                    case 'PHONE_CODE_INVALID':
                        {
                            if (this.attempt < this.maxAttempts) {
                                this.attempt += 1;
                                promise = this.sendCode();
                            } else {
                                return [2 /*return*/, this.fatalError(new Error('Exceeded the limit of failed attempts'))];
                            }
                            break;
                        }
                    case 'PASSWORD_HASH_INVALID':
                        {
                            if (this.attempt < this.maxAttempts) {
                                this.attempt += 1;
                                promise = this.checkAuthenticationPassword();
                            } else {
                                return [2 /*return*/, this.fatalError(new Error('Exceeded the limit of failed attempts'))];
                            }
                            break;
                        }
                    default:
                        {
                            return [2 /*return*/, false];
                        }
                }
                return [2 /*return*/, promise ? promise.then(function (_a) {
                    var response = _a.response;
                    if (isError(response)) {
                        _this.handleError(response);
                        return false;
                    }
                    return true;
                }).catch(function (error) {
                    return _this.fatalError(error);
                }) : true];
            });
        });
    };
    Auth.prototype.handleUpdateState = function (_a) {
        var authorizationState = _a.authorizationState;
        return __awaiter(this, void 0, void 0, function () {
            var promise, _b, token;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.attempt = 0;
                        this.authState = authorizationState;
                        promise = null;
                        _b = authorizationState._;
                        switch (_b) {
                            case AUTHORIZATION_STATE.authorizationStateWaitPhoneNumber:
                                return [3 /*break*/, 1];
                            case AUTHORIZATION_STATE.authorizationStateWaitCode:
                                return [3 /*break*/, 5];
                            case AUTHORIZATION_STATE.authorizationStateWaitRegistration:
                                return [3 /*break*/, 6];
                            case AUTHORIZATION_STATE.authorizationStateWaitPassword:
                                return [3 /*break*/, 7];
                            case AUTHORIZATION_STATE.authorizationStateReady:
                                return [3 /*break*/, 8];
                        }
                        return [3 /*break*/, 11];
                    case 1:
                        if (!this.isBot) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.ask('token')];
                    case 2:
                        token = _c.sent();
                        promise = this.airgram.api.checkAuthenticationBotToken({ token: token });
                        return [3 /*break*/, 4];
                    case 3:
                        promise = this.askPhoneNumber().then(function (phoneNumber) {
                            return _this.airgram.api.setAuthenticationPhoneNumber({
                                phoneNumber: phoneNumber
                            });
                        });
                        _c.label = 4;
                    case 4:
                        return [3 /*break*/, 12];
                    case 5:
                        {
                            promise = this.sendCode();
                            return [3 /*break*/, 12];
                        }
                        _c.label = 6;
                    case 6:
                        {
                            promise = this.registerUser();
                            return [3 /*break*/, 12];
                        }
                        _c.label = 7;
                    case 7:
                        {
                            promise = this.checkAuthenticationPassword();
                            return [3 /*break*/, 12];
                        }
                        _c.label = 8;
                    case 8:
                        if (!this.deferred) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.deferred.resolve()];
                    case 9:
                        _c.sent();
                        this.deferred = null;
                        _c.label = 10;
                    case 10:
                        return [3 /*break*/, 12];
                    case 11:
                        {
                            return [2 /*return*/, false];
                        }
                        _c.label = 12;
                    case 12:
                        return [2 /*return*/, promise ? promise.then(function (_a) {
                            var response = _a.response;
                            if (isError(response)) {
                                _this.handleError(response);
                                return false;
                            }
                            return true;
                        }).catch(function (error) {
                            return _this.fatalError(error);
                        }) : true];
                }
            });
        });
    };
    Auth.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deferred_1, authState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.deferred) return [3 /*break*/, 2];
                        deferred_1 = {};
                        deferred_1.promise = new Promise(function (resolve, reject) {
                            deferred_1.resolve = resolve;
                            deferred_1.reject = reject;
                        });
                        this.deferred = deferred_1;
                        if (!!this.authState) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.airgram.api.getAuthorizationState()];
                    case 1:
                        authState = _a.sent().response;
                        this.authState = authState || null;
                        _a.label = 2;
                    case 2:
                        return [2 /*return*/, Promise.resolve(this.deferred ? this.deferred.promise : undefined)];
                }
            });
        });
    };
    Auth.prototype.registerUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var firstName, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        return [4 /*yield*/, this.ask('firstName')];
                    case 1:
                        firstName = _d.sent();
                        if (!firstName) {
                            throw new Error('First name can not be empty.');
                        }
                        _b = (_a = this.airgram.api).registerUser;
                        _c = {
                            firstName: firstName
                        };
                        return [4 /*yield*/, this.ask('lastName')];
                    case 2:
                        return [2 /*return*/, _b.apply(_a, [(_c.lastName = _d.sent() || undefined, _c)])];
                }
            });
        });
    };
    Auth.prototype.sendCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var code;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        return [4 /*yield*/, this.ask('code')];
                    case 1:
                        code = _a.sent();
                        if (!code || !/^\d+$/.test(code)) {
                            throw new Error('Invalid authorization code');
                        }
                        return [2 /*return*/, this.airgram.api.checkAuthenticationCode({
                            code: code
                        })];
                }
            });
        });
    };
    return Auth;
}();
export { Auth };