export var TEXT_ENTITY_TYPE;
(function (TEXT_ENTITY_TYPE) {
    TEXT_ENTITY_TYPE["textEntityTypeMention"] = "textEntityTypeMention";
    TEXT_ENTITY_TYPE["textEntityTypeHashtag"] = "textEntityTypeHashtag";
    TEXT_ENTITY_TYPE["textEntityTypeCashtag"] = "textEntityTypeCashtag";
    TEXT_ENTITY_TYPE["textEntityTypeBotCommand"] = "textEntityTypeBotCommand";
    TEXT_ENTITY_TYPE["textEntityTypeUrl"] = "textEntityTypeUrl";
    TEXT_ENTITY_TYPE["textEntityTypeEmailAddress"] = "textEntityTypeEmailAddress";
    TEXT_ENTITY_TYPE["textEntityTypePhoneNumber"] = "textEntityTypePhoneNumber";
    TEXT_ENTITY_TYPE["textEntityTypeBankCardNumber"] = "textEntityTypeBankCardNumber";
    TEXT_ENTITY_TYPE["textEntityTypeBold"] = "textEntityTypeBold";
    TEXT_ENTITY_TYPE["textEntityTypeItalic"] = "textEntityTypeItalic";
    TEXT_ENTITY_TYPE["textEntityTypeUnderline"] = "textEntityTypeUnderline";
    TEXT_ENTITY_TYPE["textEntityTypeStrikethrough"] = "textEntityTypeStrikethrough";
    TEXT_ENTITY_TYPE["textEntityTypeSpoiler"] = "textEntityTypeSpoiler";
    TEXT_ENTITY_TYPE["textEntityTypeCode"] = "textEntityTypeCode";
    TEXT_ENTITY_TYPE["textEntityTypePre"] = "textEntityTypePre";
    TEXT_ENTITY_TYPE["textEntityTypePreCode"] = "textEntityTypePreCode";
    TEXT_ENTITY_TYPE["textEntityTypeTextUrl"] = "textEntityTypeTextUrl";
    TEXT_ENTITY_TYPE["textEntityTypeMentionName"] = "textEntityTypeMentionName";
    TEXT_ENTITY_TYPE["textEntityTypeMediaTimestamp"] = "textEntityTypeMediaTimestamp";
})(TEXT_ENTITY_TYPE || (TEXT_ENTITY_TYPE = {}));